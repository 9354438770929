<template>
    <div class="product-header">
        <CDropdown v-if="products.length > 0" name="product">
            <template #toggler>
                <CHeaderNavLink class="text-center acc-name">
                    <i class="brdge-icons brdge-icon-header-waffle"></i>
                </CHeaderNavLink>
            </template>
            <CDropdownItem @click="gotoProduct(product)" v-for="(product, index) in products" :key="index" name="subProduct">
                <div class="product">
                  <div class="name" v-if="product.name.toLowerCase() == 'master'"><i class="fa fa-handshake-o"></i></div>
                  <div class="name" v-if="product.name.toLowerCase() == 'streams'"><i class="brdge-icons brdge-icon-waves"></i></div>
                  <div class="name" v-if="product.name.toLowerCase() == 'connect'"><i class="fa fa-link"></i></div>
                  <div class="name" v-if="product.name.toLowerCase() == 'inventory'"><i class="fa fa-th"></i></div>
                  <div class="name" v-if="product.name.toLowerCase() == 'so'"><i class="fa fa-handshake-o"></i></div>
                  <div class="name" v-if="product.name.toLowerCase() == 'po'"><i class="fa fa-external-link"></i></div>
                  <div class="product-name">
                    <span> {{product.name}} </span>
                  </div>
                </div>
            </CDropdownItem>
        </CDropdown>
    </div>
</template>
<script>
import * as loaderService from '@common/service/loader.service';
import * as apis from '@common/utils/api/products';
import * as applicationApis from '@common/utils/api/masters/applicationrole';
export default {
  name: 'App',
  data : function(){
    return {
      isLoggedId:false,
      noProducts:false,
      isInvetoryPermission: false,
      error:"",
      products:[],
    }
  },
  methods: {
    gotoProduct: function(product){
      window.open(`${window.location.protocol}//${window.location.host}/${product.url}`,`${product.name}_tab`);
    },
    getProductLists: function (code) {
        apis.getProductLists().then((response) => {
            if(response.data.status.status_code === 0) {
                this.products = response.data.payload;
                if(this.products.length == 0){
                  this.noProducts = true;
                }
            }
        }).catch(err=>{
            this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
        });
    },
    getPermissions: function(){
        loaderService.showLoader();
        applicationApis.getRolePermission(window.keycloakImplObj.userDetails.selectedRole).then((response) => {
            if(response.data.status.status_code === 0) {
                this.showRouter = true;      
                window.keycloakImplObj.userDetails.permissions = [];
                for(let permission of response.data.payload){
                  window.keycloakImplObj.userDetails.permissions.push(permission.code);
                };
                if(window?.keycloakImplObj?.userDetails?.permissions && window.keycloakImplObj.userDetails.permissions.includes('InventoryMenu')){
                  this.isInvetoryPermission = true;
                }
                //this.$router.push('/user/dashboard')
            }
        }).catch(err=>{
            this.error = err.message;
        }).finally(()=>{
            loaderService.hideLoader();
        });
    }
  },
  mounted : function(){
    if(window.keycloakImplObj.userDetails.roles.length == 1){
      window.keycloakImplObj.userDetails.selectedRole = window.keycloakImplObj.userDetails.roles[0].role;
      this.getPermissions();
    }
    if(window.keycloakImplObj.userDetails){
      this.isLoggedId = true;
      this.getProductLists();
    }
  }
}
</script>
<style scoped>
.product-header{
    display: inline-block;
}
.product-header::v-deep .dropdown-item {
    width: 110px !important;
    min-width: unset;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
}
.product-header::v-deep .dropdown-menu.show {
    width: 222px;
}
.name{
    color: #203864;
    font-size: 32px;
    font-weight: bold;
    line-height: 35px;
    padding-top: 2px;
    margin: 0 auto;
    border-radius: 5px;
}
.product-name {
    color: #f8c045;
    font-size: 16px;
    padding-top: 2px;
    font-weight: bold;
}
</style>