import * as APIBuilder from '@common/utils/api/apiBuilder'
import * as constants from '../constants'


function fetchList(orderId) {
    return APIBuilder.buildGetRequest(constants.INTERNAL_WORK_ORDER_PROD_DETAILS+ '/' + orderId)
}
function fetchorderProdcutById(Id) {
    return APIBuilder.buildGetRequest(constants.ORDER_PROD_DETAILS_BY_ID + '/' + Id)
}
function fetchrequestOrderByType(type) {
    //return APIBuilder.buildGetRequest(`${Constants.SALES}/getProductCode/${type}`);
    return APIBuilder.buildGetRequest(`${constants.INTERNAL_WORK_ORDER_PROD_DETAILS}/requiredorders/${type}`)
}
export {
     fetchList,
    fetchorderProdcutById,
    fetchrequestOrderByType
}