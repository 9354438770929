const PRODUCT_LIST = '/api' +(window.baseUrl ? window.baseUrl : '')+'/products';
const SOD = '/api' +(window.baseUrl ? window.baseUrl : '')+'/sod';
const ASSEMBLEBOM = '/api' +(window.baseUrl ? window.baseUrl : '')+'/assemblebom';
const ORDER_DETAILS = '/api' +(window.baseUrl ? window.baseUrl : '')+'/orderdetails';
const ORDER_PROD_DETAILS = '/api' +(window.baseUrl ? window.baseUrl : '')+'/orderproductdetails';
const ORDERS_BY_SALES_ORDER = '/api' +(window.baseUrl ? window.baseUrl : '')+'/orderdetails/salesorder/';
const ORDER_PRODUCT_DETAILS = '/api' +(window.baseUrl ? window.baseUrl : '')+'/orderproductsui';
const ORDER_PRODUCT_DETAILS_LIST = '/api' +(window.baseUrl ? window.baseUrl : '') + '/orderdetails/products';
const CUSTOMER_DETAILS = '/api' +(window.baseUrl ? window.baseUrl : '')+'/customerdetails';
const UPDATE_PRODUCT_STAGE = '/api' +(window.baseUrl ? window.baseUrl : '')+'/stagesteporder/product';
const UPDATE_PRODUCT_STEP_COMPLETED = '/api' +(window.baseUrl ? window.baseUrl : '')+'/productstagestep/step/complete';
const STAGE_STEP_ORDERS_BY_ORDER = '/api' +(window.baseUrl ? window.baseUrl : '')+'/stagesteporder/order';
const STEPS_BY_PRODUCT_STAGE = '/api' +(window.baseUrl ? window.baseUrl : '') + '/productstagestep'
const PRODUCT_DETAILS_BY_ORDER_DETAILS_STAGE_SEQUENCE = '/api' +(window.baseUrl ? window.baseUrl : '') + '/productstagestep/order'
const ORDER_PRODUCTS_BOM_UI = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/orderproductsbomui';
const ORDER_PRODUCTS_BOM = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/orderproductsbom'
const BOM_EDIT = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/bom/details';
const BOM = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/bom';
const BOM_DETAILS = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/bomdetails';
//For Connect CustomizedSKU
const CUST_SKU = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/customizedsku'
const SKU_ATTRIBUTE_META ='/api'+(window.baseUrl ? window.baseUrl : '')+'/skuAttribute';
const SKU_ATTRIBUTE ='/api'+(window.baseUrl ? window.baseUrl : '')+'/skuAttributeMap';
const FULLKIT =  {
    HEIRARCHY : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/heirarchy',
    DEFAULT : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/default',
    DEFAULT_PRODUCT : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/default/product',
    GENERATE : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/generate',
    UPDATE : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/update',
}
const STAGE = '/api' + (window.baseUrl ? window.baseUrl : '') + '/stage'

const FULFILLEDFULLKIT =  {
    DEFAULT : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/fulfilled/default',
    DEFAULT_PRODUCT : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/fulfilled/default/product',
    GENERATE : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/fulfilled/generate',
    UPDATE : '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/fulfilled/update',
    PLAN: '/api' +(window.baseUrl ? window.baseUrl : '')+'/fullkit/fulfilled/planforproduction',
}

const CATEGORY_LIST = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/sku/category/leaf';
const SKU_BY_CATEGORY_LIST = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/sku/category/';


const PRODUCT_STAGE_PACKAGE_BY_PRODUCT = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagepackage/productdetails/'


const STEPS_BY_PACKAGE = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagestep/package/'

const UPADTE_REMAINING_DAYS = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagestep/remaining/'

const PACKAGE_MARK_COMPLETE = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagepackage/markcomplete'

const STAGE_MARK_COMPLETE = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagemapsnap/markcomplete/'

const STAGE_BY_PRODUCT_STAGE = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagemapsnap/product/'

const PRODUCT_STAGE_MAP_SNAP = '/api' +(window.baseUrl ? window.baseUrl : '')  + '/productstagemapsnap/'

const MANUALDATE = '/api' +(window.baseUrl ? window.baseUrl : '')+'/setmanualdate';
const CONNECT_PO = '/api' +(window.baseUrl ? window.baseUrl : '')+'/connectpo';
const CONNECT_PO_ORDER_LIST = '/api' +(window.baseUrl ? window.baseUrl : '')+'/poorderlist';
const BATCH = '/api' + (window.baseUrl ? window.baseUrl : '') + '/batch';
//Connect Work Order
const CONNECT_IWO = '/api' +(window.baseUrl ? window.baseUrl : '')+'/connectiwo';
//For IWO Hirerchy
const INTERNAL_WORK_ORDER_PROD_DETAILS = '/api' +(window.baseUrl ? window.baseUrl : '')+'/internalworkorder';
//request order Report
const REQUEST_ORDER_REPORT = '/api' +(window.baseUrl ? window.baseUrl : '')+'/requestorderreport';
const ORDER_PROD_DETAILS_BY_ID = '/api' +(window.baseUrl ? window.baseUrl : '')+'/orderproductdetailsbyid';
//check For same Date Orders
const SAME_DATE_CHECK = '/api' +(window.baseUrl ? window.baseUrl : '')+'/checksamedate';
//overall required SKU list
const OVERALL_SKU_REQUIRED = '/api' +(window.baseUrl ? window.baseUrl : '')+'/overallsku';
const WORKORDER = '/api' + (window.baseUrl ? window.baseUrl : '')+'/workorder';
const ORDER_BOM_REPORT = '/api' + (window.baseUrl ? window.baseUrl : '')+'/orderBomReport';
const ORDER_CAL_REPORT = '/api' + (window.baseUrl ? window.baseUrl : '')+'/ordercalendarreport';

export {
    PRODUCT_LIST,
    SOD,
    ORDER_DETAILS,
    ORDER_PROD_DETAILS,
    ORDERS_BY_SALES_ORDER,
    ORDER_PRODUCT_DETAILS,
    CUSTOMER_DETAILS,
    UPDATE_PRODUCT_STAGE,
    STAGE_STEP_ORDERS_BY_ORDER,
    UPDATE_PRODUCT_STEP_COMPLETED,
    STEPS_BY_PRODUCT_STAGE,
    PRODUCT_DETAILS_BY_ORDER_DETAILS_STAGE_SEQUENCE,
    ORDER_PRODUCTS_BOM_UI,
    ORDER_PRODUCTS_BOM,
    BOM_EDIT,
    FULLKIT,
    PRODUCT_STAGE_PACKAGE_BY_PRODUCT,
    STEPS_BY_PACKAGE,
    CATEGORY_LIST,
    SKU_BY_CATEGORY_LIST,
    UPADTE_REMAINING_DAYS,
    PACKAGE_MARK_COMPLETE,
    STAGE_MARK_COMPLETE,
    STAGE_BY_PRODUCT_STAGE,
    ORDER_PRODUCT_DETAILS_LIST,
    PRODUCT_STAGE_MAP_SNAP,
    FULFILLEDFULLKIT,
    ASSEMBLEBOM,
    MANUALDATE,
    STAGE,
    BOM,
    CUST_SKU,
    SKU_ATTRIBUTE_META,
    SKU_ATTRIBUTE,
    CONNECT_PO,
    CONNECT_PO_ORDER_LIST,
    BATCH,
    BOM_DETAILS,
    CONNECT_IWO,
    INTERNAL_WORK_ORDER_PROD_DETAILS,
    ORDER_PROD_DETAILS_BY_ID,
    SAME_DATE_CHECK,
    OVERALL_SKU_REQUIRED,
    WORKORDER,
    ORDER_BOM_REPORT,
    ORDER_CAL_REPORT,
    REQUEST_ORDER_REPORT
}